<template>
  <div id="app"
       class="ticket-preview-base">
    <router-view />
  </div>
</template>

<script>
import { eventBus, isDmActiveRuntime } from '@/utility';
import { localPubSubApi } from '@/api';
import config from '../config';
import deviceManagementDisplayService from './device-management/deviceManagementDisplayService';

const localPubSubSettings = {
  channel: 'TicketPreview',
  data: {
    appActive: true,
  },
};

const handleDmLocalSettings = () => {
  deviceManagementDisplayService.subscribe('TicketPreview', (data) => {
    if (data.type === 'checkStatus') {
      deviceManagementDisplayService.sendMessage(localPubSubSettings);
    }
  });
  deviceManagementDisplayService.sendMessage(localPubSubSettings);
};

const handleNarLocalSettings = () => {
  eventBus.$on('TicketPreview', (data) => {
    if (data.type === 'checkStatus') {
      localPubSubApi.publish(localPubSubSettings);
    }
  });
  // set initial state through local pubsub
  localPubSubApi.setState(localPubSubSettings).then(() => {
    localPubSubApi.publish(localPubSubSettings);
  });
};

export default {
  name: 'App',
  created() {
    if (!config.debug) {
      document.addEventListener('keydown', (e) => {
        e.stopPropagation();
        e.preventDefault();
      });
    }

    eventBus.$on('TicketChecked', (data) => {
      this.$store.dispatch('setCheckedTicket', data);

      if (this.$route.path !== '/ticket-check') {
        this.$router.push('/ticket-check').catch(() => {});
      }
    });
  },
  mounted() {
    // make sure this is set to true
    localPubSubSettings.data.appActive = true;

    if (isDmActiveRuntime()) {
      handleDmLocalSettings();
    } else {
      handleNarLocalSettings();
    }

    // disable contextmenu
    if (!config.debug) {
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      }, false);
    }
  },
  destroyed() {
    localPubSubSettings.data.appActive = false;
    if (isDmActiveRuntime()) {
      deviceManagementDisplayService.sendMessage(localPubSubSettings);
    } else {
      localPubSubApi.publish(localPubSubSettings);
    }
  },
};
</script>

<style lang="scss">
.ticket-preview-base {
  position: relative;
  min-height: 100vh;
  max-width: 100%;
  flex: 1 1 auto;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
}
</style>
