import { Display } from '@nsftx/systems-sdk';
import { logger } from '@nsoft/chameleon-sdk/src/utility';

let displayInstance;
let displayReady = false;

export default {
  initDmDisplay(core) {
    return new Promise((resolve, reject) => {
      const rejectTimeout = window.setTimeout(() => {
        displayReady = false;
        logger.error(
          '[deviceManagementDisplayService] Display instance did not receive ready event on init.',
        );
        reject(new Error('Display instance did not receive ready event on init.'));
      }, 5000);

      displayInstance = new Display(core);
      displayInstance.on('ready', () => {
        window.clearTimeout(rejectTimeout);
        displayReady = true;
        resolve(displayInstance);
      });
    });
  },

  getDisplayInstance() {
    if (!displayInstance) {
      logger.error('[deviceManagementDisplayService] Display instance is not initialized.');
      throw new Error('Display instance is not initialized.');
    }
    if (!displayReady) {
      logger.error('[deviceManagementDisplayService] Display instance did not receive ready event');
      throw new Error('Display instance did not receive ready event');
    }
    return displayInstance;
  },

  sendMessage(message) {
    if (!this.getDisplayInstance()) {
      return;
    }
    displayInstance.appMessageForward(message);
  },

  subscribe(channel, action) {
    if (!this.getDisplayInstance()) {
      return;
    }

    displayInstance.on('receive_message', ({ app_name: appName, data }) => {
      if (data?.channel === channel && appName?.includes('7Shop')) action(data);
    });
  },

  getApplication(appRunUuid) {
    const display = this.getDisplayInstance();
    return display.getApplication(appRunUuid);
  },
};
