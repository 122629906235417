import axios from 'axios';

const username = process.env.VUE_APP_DM_APPLICATION_CONFIG_BASIC_AUTH_USERNAME;
const password = process.env.VUE_APP_DM_APPLICATION_CONFIG_BASIC_AUTH_PASSWORD;

const encodeAuthorization = () => btoa(`${username}:${password}`);

const http = axios.create({
  headers: {
    Authorization: `Basic ${encodeAuthorization()}`,
  },
});

const getAppConfigData = (configUrl) => http.get(configUrl);

export default { getAppConfigData };
