import { each } from 'lodash';
import { logger } from '@nsoft/chameleon-sdk/src/utility';
import { eventBus } from '@/utility';
import config from '../../config';
import deviceManagementDisplayService from './deviceManagementDisplayService';

const eventMapper = {
  // messages about ticket updates
  '7ShopBetslip': 'TicketChanged',
  // messages for managing ticket preview visibility
  '7ShopTicketPreview': 'ModeChanged',
  // messages regarding shop user change
  '7ShopUser': 'UserChanged',
  // messages for ticket check action
  '7ShopTickets': 'TicketChecked',
  // messages for displaying customer card data
  '7ShopCustomerCard': 'CustomerCard',
};

const setListeners = () => {
  each(config.subscriptionEvents, (event) => {
    deviceManagementDisplayService.subscribe(event, (message) => {
      const mappedEvent = eventMapper[event];
      if (!mappedEvent) {
        logger.warn('Unmapped event message received', { event, message });
      } else {
        eventBus.$emit(mappedEvent, message);
      }
    });
  });
};

const initializeDmEventPublisher = () => {
  setListeners();
};

export default {
  initializeDmEventPublisher,
};
