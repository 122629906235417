import Vue from 'vue';
import { SocketClient, Sentry, isDmActiveRuntime } from './utility';
import App from './App';
import router from './router';
import store from './store';
import { loadConfig } from '../config';
import './styles/main.scss';
import deviceManagementEventPublisher from './device-management/deviceManagementEventPublisher';

Vue.config.productionTip = false;
if (!isDmActiveRuntime()) {
  Vue.prototype.$socketClient = new SocketClient();
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init(router);
}

loadConfig().then(() => {
  if (isDmActiveRuntime()) {
    deviceManagementEventPublisher.initializeDmEventPublisher();
  }
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
